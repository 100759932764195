const data = {
    products: [
        {
            _id: '1',
            name: 'War Dummies',
            category: 'Tees',
            image: '/images/war-dummies-white.webp',
            // imagetwo: '/images/white-tee-back.webp',
            size: true,
            slug: "war-dummies-white",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            sizefour: 'Extra Extra Large',
            price: 9919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- 260 gsm',
            descriptionThree: '- True to size'
        },
        {
            _id: '2',
            name: 'War Dummies',
            category: 'Tees',
            image: '/images/war-dummies-black.webp',
            // imagetwo: '/images/black-tee-back.webp',
            size: true,
            slug: "war-dummies-black",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            sizefour: 'Extra Extra Large',
            price: 9919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- 260 gsm',
            descriptionThree: '- True to size'
        },
        {
            _id: '3',
            name: 'NigerArea Dummy',
            category: 'Tees',
            image: '/images/niger-area-dummy-white.webp',
            // imagetwo: '/images/white-tee-back.webp',
            size: true,
            slug: "niger-area-dummy-white",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            sizefour: 'Extra Extra Large',
            price: 9919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- 260 gsm',
            descriptionThree: '- True to size'
        },
        {
            _id: '4',
            name: 'NigerArea Dummy',
            category: 'Tees',
            image: '/images/niger-area-dummy-black.webp',
            // imagetwo: '/images/white-tee-back.webp',
            size: true,
            slug: "niger-area-dummy-black",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            sizefour: 'Extra Extra Large',
            price: 9919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- 260 gsm',
            descriptionThree: '- True to size'
        },
        // {
        //     _id: '4',
        //     name: 'Niger Area Dummy White',
        //     category: 'Tees',
        //     image: '/images/niger-area-dummy-white.webp',
        //     // imagetwo: '/images/white-tee-back.webp',
        //     size: true,
        //     slug: "frost-tee",
        //     sizeone: 'Medium',
        //     sizetwo: 'Large',
        //     sizethree: 'Extra Large',
        //     sizefour: 'Extra Extra Large',
        //     price: 9919,
        //     CountInStock: 5,
        //     descriptionOne: '- 100% cotton tee',
        //     descriptionTwo: '- 260 gsm',
        //     descriptionThree: '- True to size'
        // },
        {
            _id: '5',
            name: 'Derby Tee',
            category: 'Tees',
            image: '/images/Ije-esin-tee-white-w-logo.webp',
            // imagetwo: '/images/white-tee-back.webp',
            size: true,
            slug: "Ije-esin-tee",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            sizefour: 'Extra Extra Large',
            price: 9919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- 260 gsm',
            descriptionThree: '- True to size'
        },
        {
            _id: '6',
            name: 'DNR Party tee',
            category: 'Tees',
            image: '/images/dnr-party-tee.webp',
            // imagetwo: '/images/white-tee-back.webp',
            size: true,
            slug: "dnr-party-tee",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            sizefour: 'Extra Extra Large',
            price: 9919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- 260 gsm',
            descriptionThree: '- True to size'
        },
        {
            _id: '7',
            name: 'For my Cuntry',
            category: 'Tees',
            image: '/images/for-my-cunt-tee-white.webp',
            // imagetwo: '/images/white-tee-back.webp',
            size: true,
            slug: "for-my-cunt-tee-white",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            sizefour: 'Extra Extra Large',
            price: 9919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- 260 gsm',
            descriptionThree: '- True to size'
        },
        {
            _id: '8',
            name: 'For my Cuntry',
            category: 'Tees',
            image: '/images/for-my-cunt-tee-black.webp',
            // imagetwo: '/images/white-tee-back.webp',
            size: true,
            slug: "for-my-cunt-tee-black",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            sizefour: 'Extra Extra Large',
            price: 9919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- 260 gsm',
            descriptionThree: '- True to size'
        },
        {
            _id: '9',
            name: 'Naij Logo Tee',
            category: 'Tees',
            image: '/images/naij-logo-tee-black.webp',
            // imagetwo: '/images/white-tee-back.webp',
            size: true,
            slug: "naij-logo-tee-black",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            sizefour: 'Extra Extra Large',
            price: 9919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- 260 gsm',
            descriptionThree: '- True to size'
        },
        {
            _id: '10',
            name: 'Naij Logo Tee',
            category: 'Tees',
            image: '/images/naija-logo-tee-white.webp',
            // imagetwo: '/images/white-tee-back.webp',
            size: true,
            slug: "naij-logo-tee-white",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            sizefour: 'Extra Extra Large',
            price: 9919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- 260 gsm',
            descriptionThree: '- True to size'
        },
        // {
        //     _id: '0',
        //     name: 'Frost Tee',
        //     category: 'Tees',
        //     image: '/images/white-tee-front.webp',
        //     imagetwo: '/images/white-tee-back.webp',
        //     size: true,
        //     slug: "frost-tee",
        //     sizeone: 'Medium',
        //     sizetwo: 'Large',
        //     sizethree: 'Extra Large',
        //     price: 15919,
        //     CountInStock: 5,
        //     descriptionOne: '- 100% cotton tee',
        //     descriptionTwo: '- loose fit '
        // },
        {
            _id: '11',
            name: 'Jade Tee',
            category: 'Tees',
            image: '/images/green-tee-front.webp',
            imagetwo: '/images/green-tee-back.webp',
            size: true,
            slug: "jade-tee",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            price: 19919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- loose fit '
        },
        {
            _id: '12',
            name: 'Wavy Blue Tee',
            category: 'Tees',
            image: '/images/blue-tee-front.webp',
            imagetwo: '/images/blue-tee-back.webp',
            size: true,
            slug: "wavy-tee",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            price: 23919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- loose fit '
        },
        // {
        //     _id: '3',
        //     name: 'Lemon Tee',
        //     category: 'Tees',
        //     image: '/images/lime-tee-front.webp',
        //     imagetwo: '/images/lime-tee-back.webp',
        //     size: true,
        //     slug: "lemon-tee",
        //     sizeone: 'Medium',
        //     sizetwo: 'Large',
        //     sizethree: 'Extra Large',
        //     price: 23919,
        //     CountInStock: 5,
        //     descriptionOne: '- 100% cotton tee',
        //     descriptionTwo: '- loose fit '
        // },
        {
            _id: '13',
            name: 'Cherry Tee',
            category: 'Tees',
            image: '/images/pink-tee-front.webp',
            imagetwo: '/images/pink-tee-back.webp',
            size: true,
            slug: "cherry-tee",
            sizeone: 'Medium',
            sizetwo: 'Large',
            sizethree: 'Extra Large',
            price: 19919,
            CountInStock: 5,
            descriptionOne: '- 100% cotton tee',
            descriptionTwo: '- loose fit '
        },
        // {
        //     _id: '5',
        //     name: 'Tangerine',
        //     category: 'Tees',
        //     image: '/images/orange-tee-front.webp',
        //     imagetwo: '/images/orange-tee-back.webp',
        //     size: true,
        //     slug: "tangerine",
        //     sizeone: 'Medium',
        //     sizetwo: 'Large',
        //     sizethree: 'Extra Large',
        //     price: 19919,
        //     CountInStock: 5,
        //     descriptionOne: '- 100% cotton tee',
        //     descriptionTwo: '- loose fit '
        // },
        // {
        //     _id: '6',
        //     name: 'Black Tee',
        //     category: 'Tees',
        //     image: '/images/black-tee-front.webp',
        //     imagetwo: '/images/black-tee-back.webp',
        //     size: true,
        //     slug: "black-tee",
        //     sizeone: 'Medium',
        //     sizetwo: 'Large',
        //     sizethree: 'Extra Large',
        //     price: 15919,
        //     CountInStock: 5,
        //     descriptionOne: '- 100% cotton tee',
        //     descriptionTwo: '- loose fit '
        // },
    ]
}
export default data;