import React, { useState } from 'react';
import data from '../data';
import { Link, useParams } from 'react-router-dom';
import Footer from '../Components/Footer';
import Navbartwofour from '../Components/Navbartwofour';
import Iconblack from '../Iconblack.png';
import { numberWithCommas } from "../helper/numberFormat.js"
import MetaTags from '../Components/Metatags.js';

const SingleProduct = (props) => {
    const { id } = useParams();
    const product = data.products.find((x) => x.slug === id);
    const [qty, setQty] = useState(1);
    const [size, setSize] = useState(product.sizeone);

    const [back, setBack] = useState(product.image)



    return (
        <div className='ps'>
            <MetaTags title={product.name} />
            <Navbartwofour></Navbartwofour>
            <div className='productscreen'>
                <Link to='/' className="linkback" onClick={() => window.scrollTo(0, 0)}>&#8592; Continue Shopping</Link>
                <div className="product-row">
                    {
                        product._id > 6 ? (<div className="col-2" style={{ flexDirection: 'column' }}>
                            {
                                product._id > 2 ? (
                                    <div className='col-222'>

                                    </div>
                                ) : (<div className='col-22'>
                                    <img src={product.image} onClick={() => setBack(product.image)} alt="io"></img>
                                    {product.imagetwo && <img src={product.imagetwo} onClick={() => setBack(product.imagetwo)} alt="io"></img>}
                                </div>)
                            }
                            <div>
                                <img className='large' src={back} alt={product.name} />
                            </div>
                        </div>) : (<div className="col-2">
                            {
                                product._id > 6 ? (
                                    <div className='col-222'>

                                    </div>
                                ) : (<div className='col-22'>
                                    <img src={product.image} onClick={() => setBack(product.image)} alt="io"></img>
                                    {product.imagetwo && <img src={product.imagetwo} onClick={() => setBack(product.imagetwo)} alt="io"></img>}
                                </div>)
                            }
                            <div>
                                <img className='large' src={back} alt={product.name} />
                            </div>
                        </div>)

                    }
                    <div className='col-11'>
                        <div className="col-1">
                            <ul>
                                <li>
                                    <h1 className='pname'>{product.name}</h1>
                                </li>
                                <li>
                                    <p> <span className='price' style={{ textTransform: 'uppercase' }} >N{numberWithCommas(product.price)}</span>
                                    </p>
                                </li>
                                <li className='descr'>
                                    <p className='desc'>{product?.descriptionOne} </p>
                                    <p className='desc'>{product?.descriptionTwo}</p>
                                    <p className='desc'>{product?.descriptionThree}</p>
                                    {/* <p className='desc'>{product.descriptionfour}</p>
                                    <p className='desc'>{product.descriptionfive}</p>
                                    <p className='desc'>{product.descriptionsix}</p>
                                    <p className='desc'>{product.descriptionseven}</p> */}
                                </li>
                            </ul>
                        </div>
                        <div className="col-3">
                            <ul>
                                <li>
                                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div> <span className='one'></span>  <span>
                                            {product.CountInStock > 0 ? (
                                                <span className='success'>In Stock</span>
                                            ) : (<span className='danger'>Unavailable</span>)}    </span>
                                        </div>
                                    </div>
                                </li>
                                {
                                    product.CountInStock > 0 && (
                                        <>
                                            <li>
                                                {
                                                    product.size === true ? (
                                                        <div className='one'>
                                                            <div className='qty'>

                                                                <select value={size} onChange={e => setSize(e.target.value)}>
                                                                    <option key={1} value={product.sizeone} >{product.sizeone}</option>
                                                                    <option key={2} value={product.sizetwo} >{product.sizetwo}</option>
                                                                    <option key={3} value={product.sizethree} >{product.sizethree}</option>
                                                                    <option key={4} value={product.sizefour} >{product.sizefour}</option>
                                                                </select>
                                                                <img src={Iconblack} alt='icon' className='drop' />

                                                                <div>
                                                                    <img src={Iconblack} alt='icon' className='drop' />
                                                                    <select value={Number(qty)} onChange={e => setQty(e.target.value)}>
                                                                        {
                                                                            [...Array(product.CountInStock).keys()].map((x) => (
                                                                                <option key={Number(x + 1)} value={x + 1} > {x + 1} </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <img src={Iconblack} alt='icon' className='drop2' />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <button onClick={() => { props.updateCart(product._id, product.name, product.price, `https://www.919dnr.com${product.image}`, product.slug, Number(qty), size) }} className='primary block'>Add Item</button>
                                                            </div>
                                                        </div>) : (
                                                        <div className='one'>
                                                            <div className='qty'>

                                                                <select value={Number(qty)} onChange={e => setQty(e.target.value)}>
                                                                    {
                                                                        [...Array(product.CountInStock).keys()].map((x) => (
                                                                            <option key={Number(x + 1)} value={x + 1} > {x + 1}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                <img src={Iconblack} alt='icon' className='drop2' />
                                                            </div>
                                                            <div>
                                                                <button onClick={() => { props.updateCart(product._id, product.name, product.price, `https://www.919dnr.com/${product.image}`, product.slug, Number(qty), size, product.size) }} className='primary block'>Add Item</button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </li>
                                        </>
                                    )

                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div >
            <Footer></Footer>
        </div>

    )
}

export default SingleProduct
